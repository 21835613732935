<template>
  <div>
    <van-tabs v-model="akId" @click="onClick">
      <van-tab title="党建动态" name="2">
        <div class="information">
          <!-- 全部 -->
          <div class="center" v-for="item in list" :key="item.index">
            <div @click="gotoUrl(item)">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.ThematicUrl" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                  <br />
                  <span class="centertime">{{ item.IssTime }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
        </div>
      </van-tab>
      <van-tab title="党员风采" name="3">
        <div class="information">
          <!-- 全部 -->
          <div class="center" v-for="item in list" :key="item.index">
            <div @click="gotoUrl(item)">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.ThematicUrl" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                  <br />
                  <span class="centertime">{{ item.IssTime }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
        </div>
      </van-tab>
      <!-- 党建活动 -->
      <!-- <van-tab title="党建活动" name="99">
        <div class="information">
          <div class="center" v-for="item in activitylist" :key="item.index">
            <router-link :to="'/partymember/activity/' + item.AId">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.AImgUrl" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Outline }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
        </div>
      </van-tab> -->
    </van-tabs>
  </div>
</template>

<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WeGetPartyInfoPage,WeGetMyActivityPage } from "@/api/RealInfo";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      akId: 2, //tab栏
      list: {}, //资讯列表
      activitylist:{}, //活动列表
      listfrom: {}, //查询传参
      hidden: true, //没有更多数据了
    };
  },
  methods: {
    gotoUrl(news) {
      if (news.WechatUrl && news.WechatUrl.length) {
        if (window.__wxjs_environment === "miniprogram") {
          window.wx.miniProgram.navigateTo({
            url: "../public/goto?path=" + encodeURIComponent(news.WechatUrl),
          });
        } else {
          window.open(news.WechatUrl);
        }
      } else {
        this.$router.push('/partymember/consult/' + news.RIId);
      }
    },
    // 获取分页列表
    getList() {
      WeGetPartyInfoPage({ riKind: this.akId })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].ThematicUrl == "") {
                  this.list[i].ThematicUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => {});
    },
    // 获取活动分页列表
    getactivityList() {
      WeGetMyActivityPage({akId:0})
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.activitylist = {};
            } else {
              this.activitylist = res.data.data;
              for (var i = 0; i < this.activitylist.length; i++) {
                if (this.activitylist[i].AImgUrl == "") {
                  this.activitylist[i].AImgUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => {});
    },
    // tan点击切换事件
    onClick(e) {
      this.akId = e;
      this.getList();
    },
  },
  mounted() {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getList();
    this.getactivityList();
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>
